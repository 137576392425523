<template>
    <div class="pdf-demo" id="demo" ref="demo">
    </div>
</template>

<script>
    import Pdfh5 from "pdfh5";
    import "pdfh5/css/pdfh5.css";
    
    // import { getUser } from '../api/common.js';
    // import { setWaterMark, removeWatermark } from "../utils/watermark";
    import { toast } from '@/utils/common.js';
    export default {
        activated() {
            // var url = this.$route.query.url;
            // console.log();
            var url = this.$route.query.url;
            if(url){
                console.log(decodeURI(url));
                this.initPdf(decodeURI(url));
                //this.initPdf('https://www.gjtool.cn/pdfh5/git.pdf');
            }
            this.hideTab();
        },
        methods:{
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            },
            initPdf(pdfurl){
                if(this.pdfh5){
                    this.pdfh5.destroy();
                    this.pdfh5 = null;
                }
                this.pdfh5 = new Pdfh5(this.$refs.demo, {
                   pdfurl: pdfurl,
                }).on('complete', (status, msg)=>{
                    if(status == 'error'){
                       return  toast(msg);
                    }
                })
            },
        }
    }
</script>

<style scoped>
    /* @import "pdfh5/css/pdfh5.css"; */
    .pdf-demo{
        min-height: 100vh;
    }
</style>